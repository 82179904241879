/* COPIED FROM ZENCHEF-UI */

.country-list {
  width: 273px;
  max-width: 273px;
  margin: 8px 0 0 -1px !important;
  border-radius: 2px;
  max-height: 228px;
  overflow: hidden;
}

.country-list > li:first-child {
  margin-top: 10px;
}

.country-list > li:last-child {
  margin-bottom: 10px;
}

.country-list > .country {
  padding: 13px 11px !important;
  border-bottom: solid 1px #f3f2f3;
  font-size: 13px;
  width: 92%;
  margin: auto;
  display: flex;
  align-items: center;
}

  .country-list > .country > .highlight {
  background-color: white !important;
  font-weight: 500;
  }

  .country-list > .country:last-child {
  border-bottom: none;
  }

  .country-list > .country > .dial-code {
  float: right;
  }

  .intl-tel-input {
  width: 100% !important;
  }
  
  .intl-tel-input input[type=tel]:focus {
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  }
  
  .intl-tel-input:not(.allow-dropdown) .flag-container {
  right: auto;
  }

  .intl-tel-input:not(.allow-dropdown) .selected-flag .iti-flag {
  display: none;
  }

.flag-container > input {
  color: #606060;
}

.iti-arrow {
  border: none !important;
  margin-top: -10px !important;
  right: 18px !important;
  font-size: 19px;

  top: 50% !important;
  left: 33px !important;
}

.iti-arrow:before {
  font-family: "aromaticons" !important;
  content: "\e97f";
  color: grey;
}

.divider {
  margin: -1px 0 7px 0 !important;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid white !important;
}

.divider:before {
  content: none;
}

.country-list {
  overflow-y: scroll;
}

.country-list > .country > .country-name {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606060; 
}

.country-list > .country > .dial-code {
  float: none;
  flex-grow: 1;
  text-align: right;
}

.country-list > .country.highlight {
  background-color: #f7f7f7 !important;
  font-weight: 500;
}